'use strict';

angular.module('informaApp')
    .directive('infGanttChart', function ($timeout, GanttChartHelper, GanttChartDataFilter) {
        return {
            restrict: 'E',
            templateUrl: 'directives/inf-gantt-chart/template.ptl.html',
            scope: {
                data: '=',
                filter: '=',
            },
            filterData(data, filter) {
                const result = _.merge([], data);

                return result.filter(x => GanttChartDataFilter.filterByStatus(x.status.success, filter));
            },
            updateChart(scope, chartContainer) {
                scope.isUpdating = true;

                $timeout(() => {
                    const filteredData = this.filterData(scope.data, scope.filter);

                    _.forEach(filteredData, x => x.hasInvalidData = GanttChartDataFilter.hasInvalidData(x));

                    this.createChart(filteredData, chartContainer);

                    scope.isUpdating = false;
                })
            },
            createChart(data, chartContainer) {
                const startingDates = data.map(function (x) {
                    return x.startingDate;
                });

                const endDates = data.map(function (x) {
                    return x.endDate;
                });

                return new GanttChartHelper.Chart(chartContainer[0], {
                    source: {
                        data: data,
                        startDate: _.min(startingDates),
                        endDate: _.max(endDates),
                    }
                });
            },
            link(scope, element, attr) {
                const chartContainer = element.find('.chart-container');

                scope.isUpdating = false;

                scope.$watch('data', (newValue) => {
                    if (newValue) {
                        this.updateChart(scope, chartContainer);
                    }
                });

                scope.$watch('filter', () => this.updateChart(scope, chartContainer), true);
            }
        }
    });
